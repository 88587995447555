import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Send, Copy, Loader2 } from 'lucide-react';
import { useChat } from '@/lib/chat-context';
import { useToast } from '@/hooks/use-toast';
import { streamChatCompletion } from '@/lib/grok-api';
import { GrokError } from '@/lib/grok-api';

// Add Message type interface
interface Message {
  id: string;
  content: string;
  role: 'user' | 'assistant';
  timestamp: Date;
  tokens?: number;
  cost?: number;
}

export function ChatWindow() {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [] = useState(false);
  const { currentSession, addMessage, updateLastMessage } = useChat();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { toast } = useToast();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // Add debugging for mount
  useEffect(() => {
    console.log('ChatWindow mounted');
    inputRef.current?.focus();
  }, []);

  // Add debugging for session changes
  useEffect(() => {
    console.log('Current session:', currentSession);
    console.log('Current messages:', currentSession?.messages);
  }, [currentSession]);

  // Add scroll effect
  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      const { scrollHeight, clientHeight } = scrollRef.current;
      scrollRef.current.scrollTo({
        top: scrollHeight - clientHeight,
        behavior: 'smooth'
      });
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timeoutId);
  }, [currentSession?.messages, scrollToBottom]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submit clicked, input:', input);
    
    if (!input.trim() || isLoading || !currentSession) {
      console.log('Submit blocked:', { 
        emptyInput: !input.trim(), 
        isLoading, 
        noSession: !currentSession 
      });
      return;
    }

    setIsLoading(true);
    const userMessage = input.trim();
    setInput('');

    const maxRetries = 2;
    let retryCount = 0;

    const attemptStreamResponse = async (): Promise<void> => {
      try {
        // Add user message
        await addMessage(userMessage, 'user');

        // Get the updated session after adding the user message
        const updatedSession = currentSession;
        if (!updatedSession) {
          throw new Error('Session not found');
        }

        // Prepare messages for API call - include the user message we just added
        const messages = [
          ...updatedSession.messages.slice(-4), // Get last 4 previous messages
          { role: 'user' as const, content: userMessage } // Add current message
        ].map(msg => ({
          role: msg.role as 'user' | 'assistant' | 'system',
          content: msg.content
        }));

        console.log('Prepared messages for API:', messages); // Debug log

        // Add initial AI message placeholder
        await addMessage('', 'assistant');

        // Stream the response
        let aiMessage = '';
        for await (const chunk of streamChatCompletion(messages)) {
          console.log('Received chunk:', chunk);
          aiMessage += chunk;
          await updateLastMessage(aiMessage);
        }
      } catch (error) {
        console.error('Streaming error:', error);
        
        // If it's a 500 error and we haven't exceeded retries, try again
        if (error instanceof GrokError && 
            error.code === 'SERVER_ERROR' && 
            retryCount < maxRetries) {
          retryCount++;
          console.log(`Retrying attempt ${retryCount}...`);
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second
          return attemptStreamResponse();
        }

        toast({
          title: 'Error',
          description: error instanceof Error ? error.message : 'Failed to stream response',
          variant: 'destructive',
        });
        await updateLastMessage('Sorry, I encountered an error while generating the response.');
      }
    };

    try {
      await attemptStreamResponse();
    } catch (error) {
      console.error('Chat error:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to send message',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Add memoization for message rendering
  const MessageItem = React.memo(({ message }: { message: Message }) => {
    const { toast } = useToast();
    
    const copyToClipboard = async (text: string) => {
      await navigator.clipboard.writeText(text);
      toast({
        title: 'Copied to clipboard',
        duration: 2000,
      });
    };

    return (
      <div
        className={`flex ${
          message.role === 'user' ? 'justify-end' : 'justify-start'
        }`}
      >
        <div
          className={`relative group max-w-[80%] px-4 py-2 rounded-lg ${
            message.role === 'user'
              ? 'bg-primary text-primary-foreground ml-12'
              : 'bg-muted text-muted-foreground mr-12'
          }`}
        >
          <pre className="whitespace-pre-wrap font-sans">
            {message.content || ''}
          </pre>
          {message.tokens && (
            <p className="text-xs mt-1 opacity-70">
              Tokens: {message.tokens} | Cost: ${message.cost?.toFixed(4)}
            </p>
          )}
          <Button
            variant="ghost"
            size="icon"
            className="absolute -right-10 top-0 opacity-0 group-hover:opacity-100 transition-opacity"
            onClick={() => copyToClipboard(message.content)}
          >
            <Copy className="h-4 w-4" />
          </Button>
        </div>
      </div>
    );
  });
  MessageItem.displayName = 'MessageItem';

  if (!currentSession) {
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <p className="text-muted-foreground">Start a new chat to begin</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full p-4">
      {/* Replace ScrollArea with a regular div for testing */}
      <div className="flex-1 overflow-y-auto p-4" ref={scrollRef}>
        <div className="space-y-4">
          {currentSession?.messages?.map((message) => (
            <MessageItem key={message.id} message={message} />
          ))}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="pt-4">
        <div className="flex gap-2">
          <Textarea
            id="chat-input"
            name="chat-input"
            ref={inputRef}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className="min-h-[60px] neon-border"
            autoFocus
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          />
          <Button 
            type="submit" 
            size="icon" 
            className="h-[60px] w-[60px]"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader2 className="h-5 w-5 animate-spin" />
            ) : (
              <Send className="h-5 w-5" />
            )}
          </Button>
        </div>
      </form>

      {currentSession?.totalTokens > 0 && (
        <div className="mt-2 text-sm text-muted-foreground text-right">
          Session Total - Tokens: {currentSession.totalTokens} | Cost: ${currentSession.totalCost.toFixed(4)}
        </div>
      )}
    </div>
  );
}
