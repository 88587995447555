import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@/components/theme-provider';
import { Toaster } from '@/components/ui/toaster';
import { ChatProvider } from '@/lib/chat-context';
import { AuthProvider } from '@/lib/auth-context';
import ChatLayout from '@/components/chat/chat-layout';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider defaultTheme="dark" storageKey="ui-theme">
        <AuthProvider>
          <ChatProvider>
            <ChatLayout />
            <Toaster />
          </ChatProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
