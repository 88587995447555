export interface ChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

export interface TokenUsage {
  prompt: number;
  completion: number;
  total: number;
  cost: number;
}

// xAI Grok API specific constants
export const GROK_CONSTANTS = {
  MODEL_NAME: 'grok-beta',
  MAX_CONTEXT: 131072,
  COST_PER_1K_INPUT: 5.0,
  COST_PER_1K_OUTPUT: 15.0,
  RPS: 1,
  RPM: 60,
  RPH: 1200
} as const;
