import { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useToast } from '@/hooks/use-toast';
import JSZip from 'jszip';

interface Message {
  id: string;
  content: string;
  role: 'user' | 'assistant';
  timestamp: Date;
  tokens?: number;
  cost?: number;
}

interface ChatSession {
  id: string;
  name: string;
  messages: Message[];
  createdAt: Date;
  totalTokens: number;
  totalCost: number;
}

interface ChatContextType {
  sessions: ChatSession[];
  currentSession: ChatSession | null;
  createSession: () => void;
  deleteSession: (id: string) => void;
  setCurrentSession: (id: string) => void;
  addMessage: (content: string, role: 'user' | 'assistant') => Promise<void>;
  updateLastMessage: (content: string) => void;
  downloadSession: (id: string) => void;
  downloadAllSessions: () => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

const STORAGE_KEY = 'chat-sessions';
const TOKEN_COST = 0.000002; // Adjust this based on actual x.ai pricing

function generateChatName(content: string): string {
  // Extract first sentence or first 30 characters
  const firstSentence = content.split(/[.!?]/)[0].trim();
  return firstSentence.length > 30 
    ? firstSentence.substring(0, 30) + '...'
    : firstSentence;
}

// Update token estimation to be more accurate
function estimateTokens(text: string): number {
  // More accurate token estimation for GPT models
  // Roughly 4 chars per token for English text
  return Math.ceil(text.length / 4);
}

export function ChatProvider({ children }: { children: React.ReactNode }) {
  const [sessions, setSessions] = useState<ChatSession[]>(() => {
    const saved = localStorage.getItem(STORAGE_KEY);
    return saved ? JSON.parse(saved) : [];
  });
  const [currentSession, setCurrentSessionState] = useState<ChatSession | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(sessions));
  }, [sessions]);

  useEffect(() => {
    console.log('ChatProvider mounted');
  }, []);

  const createSession = useCallback(() => {
    const newSession: ChatSession = {
      id: crypto.randomUUID(),
      name: 'New Chat',
      messages: [],
      createdAt: new Date(),
      totalTokens: 0,
      totalCost: 0,
    };
    setSessions(prev => [newSession, ...prev]);
    setCurrentSessionState(newSession);
  }, []);

  const deleteSession = useCallback((id: string) => {
    setSessions(prev => prev.filter(session => session.id !== id));
    if (currentSession?.id === id) {
      const remainingSessions = sessions.filter(s => s.id !== id);
      setCurrentSessionState(remainingSessions[0] || null);
    }
  }, [currentSession, sessions]);

  const setCurrentSession = useCallback((id: string) => {
    const session = sessions.find(s => s.id === id);
    if (session) {
      setCurrentSessionState(session);
    }
  }, [sessions]);

  const addMessage = useCallback(async (content: string, role: 'user' | 'assistant') => {
    console.log('Adding message:', { content, role });
    const tokens = estimateTokens(content);
    const cost = tokens * TOKEN_COST;

    const message: Message = {
      id: crypto.randomUUID(),
      content,
      role,
      timestamp: new Date(),
      tokens,
      cost,
    };

    setSessions(prev => {
      if (!currentSession) return prev;
      
      return prev.map(session => {
        if (session.id === currentSession.id) {
          const isFirstUserMessage = role === 'user' && session.messages.length === 0;
          const newName = isFirstUserMessage ? generateChatName(content) : session.name;
          
          const updatedSession = {
            ...session,
            name: newName,
            messages: [...session.messages, message],
            totalTokens: session.totalTokens + tokens,
            totalCost: session.totalCost + cost,
          };
          // Update current session state
          setCurrentSessionState(updatedSession);
          return updatedSession;
        }
        return session;
      });
    });
  }, [currentSession, setCurrentSessionState]);

  const updateLastMessage = useCallback((content: string) => {
    console.log('Updating last message:', content);
    setSessions(prev => {
      if (!currentSession) return prev;
      
      return prev.map(session => {
        if (session.id === currentSession.id) {
          const messages = [...session.messages];
          if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage.role === 'assistant') {
              const tokens = estimateTokens(content);
              const cost = tokens * TOKEN_COST;
              messages[messages.length - 1] = {
                ...lastMessage,
                content,
                tokens,
                cost,
              };
            }
          }
          const updatedSession = {
            ...session,
            messages,
            totalTokens: messages.reduce((sum, msg) => sum + (msg.tokens || 0), 0),
            totalCost: messages.reduce((sum, msg) => sum + (msg.cost || 0), 0),
          };
          // Update current session state
          setCurrentSessionState(updatedSession);
          return updatedSession;
        }
        return session;
      });
    });
  }, [currentSession, setCurrentSessionState]);

  const downloadSession = useCallback((id: string) => {
    const session = sessions.find(s => s.id === id);
    if (!session) return;

    const content = session.messages
      .map(msg => `${msg.role.toUpperCase()}: ${msg.content}\n`)
      .join('\n');

    const blob = new Blob([content], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${session.name.replace(/[^a-z0-9]/gi, '_')}.md`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast({
      title: 'Chat Downloaded',
      description: `${session.name} has been downloaded successfully.`,
    });
  }, [sessions, toast]);

  const downloadAllSessions = useCallback(async () => {
    const zip = new JSZip();

    sessions.forEach(session => {
      const content = session.messages
        .map(msg => `${msg.role.toUpperCase()}: ${msg.content}\n`)
        .join('\n');
      zip.file(`${session.name.replace(/[^a-z0-9]/gi, '_')}.md`, content);
    });

    const blob = await zip.generateAsync({ type: 'blob' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `grok_chats_${new Date().toISOString()}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast({
      title: 'All Chats Downloaded',
      description: 'All chat sessions have been downloaded successfully.',
    });
  }, [sessions, toast]);

  return (
    <ChatContext.Provider
      value={{
        sessions,
        currentSession,
        createSession,
        deleteSession,
        setCurrentSession,
        addMessage,
        updateLastMessage,
        downloadSession,
        downloadAllSessions,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}
