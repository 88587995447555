import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Sidebar } from './sidebar';
import { ChatWindow } from './chat-window';
import { PanelLeftOpen, PanelLeftClose } from 'lucide-react';
import { useChat } from '@/lib/chat-context';

export default function ChatLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { currentSession, createSession } = useChat();

  useEffect(() => {
    if (!currentSession) {
      createSession();
    }
  }, [currentSession, createSession]);

  return (
    <div className="flex h-screen overflow-hidden">
      <div
        className={`${
          sidebarOpen ? 'w-80' : 'w-0'
        } transition-all duration-300 ease-in-out`}
      >
        <Sidebar />
      </div>

      <div className="flex-1 flex flex-col">
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-4 left-4 z-50"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen ? (
            <PanelLeftClose className="h-5 w-5" />
          ) : (
            <PanelLeftOpen className="h-5 w-5" />
          )}
        </Button>
        <ChatWindow />
      </div>
    </div>
  );
}
