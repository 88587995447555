import { createContext, useContext, useState } from 'react';
import type { User, AuthState, AuthContextType } from './types/auth';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitializing: true,
  isLoading: false,
  user: null,
  error: null
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<AuthState>(initialState);

  const login = async (email: string, _password: string) => {
    setState(prev => ({ ...prev, isLoading: true, error: null }));
    try {
      const mockUser: User = {
        id: '1',
        name: 'John',
        surname: 'Doe',
        email: email,
        role: 'beta',
        status: 'active',
        currency: 'USD',
        createdAt: new Date()
      };
      setState(prev => ({ 
        ...prev, 
        user: mockUser, 
        isAuthenticated: true,
        isLoading: false 
      }));
    } catch (error) {
      setState(prev => ({ 
        ...prev, 
        error: 'Login failed', 
        isLoading: false 
      }));
    }
  };

  const logout = async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      setState(prev => ({ 
        ...prev,
        user: null, 
        isAuthenticated: false,
        isLoading: false 
      }));
    } catch (error) {
      setState(prev => ({ 
        ...prev, 
        error: 'Logout failed',
        isLoading: false 
      }));
    }
  };

  const registerBeta = async (_email: string, _name: string, _reason: string) => {
    setState(prev => ({ ...prev, isLoading: true, error: null }));
    try {
      // Simulate API call
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (error) {
      setState(prev => ({ 
        ...prev, 
        error: 'Registration failed',
        isLoading: false 
      }));
    }
  };

  const updateProfile = async (data: Partial<User>) => {
    setState(prev => ({ ...prev, isLoading: true, error: null }));
    try {
      if (!state.user) throw new Error('No user logged in');
      const updatedUser = { ...state.user, ...data };
      setState(prev => ({ 
        ...prev,
        user: updatedUser,
        isLoading: false 
      }));
    } catch (error) {
      setState(prev => ({ 
        ...prev, 
        error: 'Profile update failed',
        isLoading: false 
      }));
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        ...state,
        login,
        logout,
        registerBeta,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
