import { ChatMessage, GROK_CONSTANTS } from './types/chat';

// Get environment variables with fallbacks
const getEnvVar = (key: 'API_KEY' | 'API_URL' | 'MODEL') => {
  const envVars = {
    API_KEY: import.meta.env.VITE_GROK_API_KEY,
    API_URL: import.meta.env.VITE_GROK_API_URL || 'https://api.x.ai',
    MODEL: import.meta.env.VITE_GROK_MODEL || 'grok-beta'
  };

  const value = envVars[key];
  if (!value) {
    console.error(`Environment variable ${key} not found`, {
      hasKey: !!envVars.API_KEY,
      url: envVars.API_URL,
      model: envVars.MODEL
    });
  }
  return value;
};

const getApiConfig = () => ({
  API_KEY: getEnvVar('API_KEY'),
  MODEL: GROK_CONSTANTS.MODEL_NAME
});

export class GrokError extends Error {
  constructor(message: string, public code: string) {
    super(message);
    this.name = 'GrokError';
  }
}

export async function* streamChatCompletion(messages: ChatMessage[]) {
  const { API_KEY, MODEL } = getApiConfig();

  if (!API_KEY) {
    console.error('API key not found in environment variables');
    throw new GrokError('API key not configured', 'NO_API_KEY');
  }

  let reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
  
  try {
    // Make request to our Worker backend with new API path
    const workerUrl = 'https://chat.xstra.ai/api/v1/chat/completions';
    console.log('Making request to Worker:', workerUrl, {
      hasApiKey: !!API_KEY,
      model: MODEL
    });
    
    const response = await fetch(workerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${API_KEY}`,
        'Accept': 'text/event-stream',
      },
      body: JSON.stringify({
        model: MODEL,
        messages,
        stream: true,
        max_tokens: GROK_CONSTANTS.MAX_CONTEXT,
        temperature: 0.7,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', {
        status: response.status,
        statusText: response.statusText,
        body: errorText,
        url: workerUrl
      });
      
      let errorMessage = 'An error occurred while processing your request.';
      let errorCode = 'API_ERROR';

      try {
        const error = JSON.parse(errorText);
        errorMessage = error.error?.message || error.details || errorMessage;
        errorCode = error.error?.code || errorCode;
      } catch (e) {
        // If error text isn't JSON, use it directly
        errorMessage = errorText || errorMessage;
      }

      if (response.status === 401) {
        throw new GrokError('Authentication failed. Please check your API key.', 'AUTH_ERROR');
      } else if (response.status === 500) {
        throw new GrokError('Server error occurred. Please try again.', 'SERVER_ERROR');
      } else {
        throw new GrokError(errorMessage, errorCode);
      }
    }

    if (!response.body) {
      throw new GrokError('No response body', 'NO_RESPONSE_BODY');
    }

    reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk
        .split('\n')
        .filter(line => line.trim().startsWith('data: '));

      for (const line of lines) {
        const data = line.slice(6); // Remove 'data: ' prefix
        if (data === '[DONE]') return;

        try {
          const parsed = JSON.parse(data);
          const content = parsed.choices[0]?.delta?.content;
          if (content) yield content;
        } catch (e) {
          console.warn('Failed to parse chunk:', data);
        }
      }
    }
  } catch (error) {
    // Ignore KV namespace errors as they're related to static assets
    if (error instanceof Error && error.message.includes('KV namespace')) {
      console.warn('KV namespace error (safe to ignore):', error);
      return;
    }

    console.error("Stream error:", error);
    if (error instanceof GrokError) {
      throw error;
    }
    throw new GrokError(
      error instanceof Error ? error.message : "An unknown error occurred",
      "STREAM_ERROR",
    );
  } finally {
    if (reader) {
      try {
        await reader.cancel();
      } catch (e) {
        // Ignore cancel errors
      }
    }
  }
}

// Helper function to calculate token costs
export function calculateCost(inputTokens: number, outputTokens: number): number {
  const inputCost = (inputTokens / 1000) * GROK_CONSTANTS.COST_PER_1K_INPUT;
  const outputCost = (outputTokens / 1000) * GROK_CONSTANTS.COST_PER_1K_OUTPUT;
  return inputCost + outputCost;
}
