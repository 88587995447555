import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Search,
  Plus,
  RefreshCw,
  Upload,
  MessageSquare,
  Settings,
  Download,
  Trash2,
  User,
} from 'lucide-react';
import { useChat } from '@/lib/chat-context';
import { format } from 'date-fns';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useNavigate } from 'react-router-dom';

export function Sidebar() {
  const [search, setSearch] = useState('');
  const {
    sessions,
    createSession,
    deleteSession,
    currentSession,
    setCurrentSession,
    downloadSession,
    downloadAllSessions,
  } = useChat();
  const navigate = useNavigate();

  const filteredSessions = sessions.filter((session) =>
    session.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleNewChat = () => {
    createSession();
  };

  const handleExportAll = () => {
    downloadAllSessions();
  };

  const handleSettings = () => {
    navigate('/settings');
  };

  return (
    <div className="h-full border-r bg-muted/50 backdrop-blur-xl">
      <div className="flex flex-col h-full p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold gradient-text">Grok Chat</h2>
          <div className="flex gap-2">
            <Button variant="ghost" size="icon" onClick={handleExportAll}>
              <Download className="h-5 w-5" />
            </Button>
            <Button variant="ghost" size="icon" onClick={handleSettings}>
              <User className="h-5 w-5" />
            </Button>
          </div>
        </div>

        <div className="flex gap-2 mb-4">
          <Button className="flex-1" onClick={handleNewChat}>
            <Plus className="h-4 w-4 mr-2" />
            New Chat
          </Button>
          <Button variant="outline" size="icon">
            <RefreshCw className="h-4 w-4" />
          </Button>
          <Button variant="outline" size="icon">
            <Upload className="h-4 w-4" />
          </Button>
        </div>

        <div className="relative mb-4">
          <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search chats..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="pl-9"
          />
        </div>

        <ScrollArea className="flex-1">
          <div className="space-y-2">
            {filteredSessions.map((session) => (
              <div key={session.id} className="flex items-center gap-2">
                <Button
                  variant={currentSession?.id === session.id ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  onClick={() => setCurrentSession(session.id)}
                >
                  <MessageSquare className="h-4 w-4 mr-2" />
                  <div className="flex-1 text-left">
                    <p className="text-sm font-medium truncate">{session.name}</p>
                    <p className="text-xs text-muted-foreground">
                      {format(new Date(session.createdAt), 'MMM d, yyyy')}
                    </p>
                  </div>
                </Button>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon">
                      <Settings className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={() => downloadSession(session.id)}>
                      <Download className="h-4 w-4 mr-2" />
                      Export Chat
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="text-destructive"
                      onClick={() => deleteSession(session.id)}
                    >
                      <Trash2 className="h-4 w-4 mr-2" />
                      Delete Chat
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}